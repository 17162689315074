const currentScript =
  document.currentScript || document.querySelector('script[src*="token/CollectCheckout.js"]');

function getRoot(url) {
  const pathArray = url.split('/');

  pathArray.pop();
  pathArray.pop();

  return pathArray.join('/');
}

export default {
  /**
   * @returns {string}
   */
  get origin() {
    const pathArray = this.scriptUrl.split('/');
    if (pathArray.length < 3) {
      throw new Error('Invalid script src');
    }
    const protocol = pathArray[0];
    const host = pathArray[2];
    return `${protocol}//${host}`;
  },

  get createCartUrl() {
    return `${getRoot(this.scriptUrl)}/api/v4/cart`;
  },

  generateCartRedirectLink(id) {
    return this.isDevelopment() || this.isStaging()
      ? `${getRoot(this.scriptUrl)}/collect-checkout/?cartId=${encodeURI(id)}`
      : `https://collectcheckout.com/collect-checkout/?cartId=${encodeURI(id)}`;
  },

  /**
   * @returns {string | null}
   */
  get scriptUrl() {
    return currentScript.getAttribute('src');
  },

  /**
   * @returns {HTMLScriptElement | SVGScriptElement | Element}
   */
  get currentScriptNode() {
    return currentScript;
  },

  isDevelopment() {
    const url = new URL(window.location);

    return ['dev0-sch.corp.nmi.com', 'dev1-sch.corp.nmi.com', 'transdev.nmi.com'].includes(
      url.hostname,
    );
  },

  isStaging() {
    const url = new URL(window.location);

    return ['secure.nmihq.com'].includes(url.hostname);
  },
};
